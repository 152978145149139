import GuidUtils from '@/shared/utils/guid'

export default class StudentDeficiency {
  constructor({
    id,
    studentId,
    deficiencyTypeId,
    observation,
    cidCode
    
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.studentId = studentId,
    this.deficiencyTypeId = deficiencyTypeId,
    this.observation = observation,
    this.cidCode = cidCode
  }
}