export default {
  name: 'defaultMixin',

  data: () => ({}),

  methods: {
    replaceCommaWithPoint(value) {
      if (!value) return

      let v = value.toString()
      const hasComma = v.includes(',')
      if (!hasComma) return v

      v= v.replace(',', '.')
      return v
    }
  }
}
