import GuidUtils from '@/shared/utils/guid'

export default class Anthropometry {
  constructor({
    id,
    studentId,
    date,
    weight,
    height,
    abdominalCirc,
    armCirc,
    thighCirc,
    calfCirc,
    chestCirc,
    hip,
    triceps,
    chest,
    abdominal,
    supraIliac,
    thighCrease,
    bodyFat
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.studentId = studentId
    this.date = date,
    this.weight = weight,
    this.height = height,
    this.abdominalCirc = abdominalCirc,
    this.armCirc = armCirc,
    this.thighCirc = thighCirc,
    this.calfCirc = calfCirc,
    this.chestCirc = chestCirc,
    this.hip = hip,
    this.triceps = triceps,
    this.chest = chest,
    this.abdominal = abdominal,
    this.supraIliac = supraIliac,
    this.thighCrease = thighCrease,
    this.bodyFat = bodyFat
  }
}
