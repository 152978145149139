import BenefitTypeRepository from '@/shared/http/repositories/socialProject/benefit-type'
import BenefitRepository from '@/shared/http/repositories/socialProject/student-benefit'
import StudentBenefit from '@/shared/models/studentBenefit'
import GuidUtils from '@/shared/utils/guid'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { Money } from 'v-money'
import { moneyFormattedFilter } from '@/shared/filters/ToMoneyFormatted'
import Roles from '@/shared/mixins/hasRoles'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableBenefit',

  mixins: [Roles],

  component: {
    Money
  },

  data: () => ({
    studentBenefit: new StudentBenefit(),
    emptyText: 'Não há benefícios para mostrar',
    showModalDeleteBenefit: false,
    showModalBenefit: false,
    studentBenefities: [],
    studentBenefitTypes: [],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false
    },
    fields: [
      {
        key: 'benefitTypeName',
        label: 'TIPO DO BENEFÍCIO',
        class: 'left-header-border text-center'
      },
      {
        key: 'value',
        label: 'VALOR',
        class: 'middle-header text-center',
        formatter: moneyFormattedFilter
      },
      {
        key: 'observations',
        label: 'OBSERVAÇÃO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    clearForm() {
      this.studentBenefit = new StudentBenefit()
      this.studentBenefit.studentId = this.student.id
    },

    onAddBenefit() {
      this.clearForm()
      this.$validator.reset()
      this.showModalBenefit = true
    },

    onEditBenefit(studentBenefit) {
      this.clearForm()
      this.studentBenefit = new StudentBenefit(studentBenefit)
      this.showModalBenefit = true
    },

    getAllBenefitTypes() {
      loading.push()
      BenefitTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentBenefitTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de benefício', 'ERRO')
        })
    },

    getBenefitByStudent() {
      this.clearForm()
      this.$validator.reset()
      loading.push()
      BenefitRepository.GetById(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentBenefities = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar atendimento social', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.studentBenefit.id)

        if(isNew){
          this.createStudentBenefit()
          return
        }

        this.updateStudentBenefit()
      })
    },

    createStudentBenefit() {
      loading.push()
      BenefitRepository.Create(this.studentBenefit)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getBenefitByStudent()
          this.showModalBenefit = false
          toast.success('Benefício criado com sucesso!', 'CRIAR BENEFÍCIO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar benefício', 'ERRO')
        })
    },

    updateStudentBenefit() {
      loading.push()
      BenefitRepository.Update(this.studentBenefit)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getBenefitByStudent()
          this.showModalBenefit = false
          toast.success('Benefício editado com sucesso!', 'EDITAR BENEFÍCIO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar benefício', 'ERRO')
        })
    },

    deleteStudentBenefit() {
      loading.push()
      BenefitRepository.Delete(this.studentBenefit.studentId, this.studentBenefit.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getBenefitByStudent()
          this.showModalDeleteBenefit = false
          toast.success('Benefício excluído com sucesso!', 'EXCLUSÃO DE BENEFÍCIO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir benefício', 'ERRO')
        })
    },

    onSelectBenefitType() {
      if (this.studentBenefit.studentBenefitTypeId) {
        const studentBenefitType = this.studentBenefitTypes.filter(studentBenefitType => studentBenefitType.id === this.studentBenefit.studentBenefitTypeId)

        if (studentBenefitType.length > 0) {
          this.studentBenefit.studentBenefitTypeName = studentBenefitType[0].name
        }
      }
    },

    selectRow(id) {
      this.showModalDeleteBenefit = true
      this.studentBenefit.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
