import StudentRepository from '@/shared/http/repositories/socialProject/student'
import StudentDeficiencyRepository from '@/shared/http/repositories/socialProject/student-deficiency'
import StudentList from './components/StudentList/studentList.vue'
import TableContact from './components/TableContact/tableContact.vue'
import TableHistoricSms from './components/TableHistoricSms/historicMessageSms.vue'
import TableMeasurement from './components/TableMeasurement/tableMeasurement.vue'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import TableRegistration from './components/TableRegistration/tableRegistration.vue'
import TableAttachment from './components/TableAttachment/tableAttachment.vue'
import TableOccurrence from './components/TableOccurrence/tableOccurrence.vue'
import TableSocialAttendance from './components/TableSocialAttendance/tableSocialAttendance.vue'
import TableMoves from './components/TableMoves/tableMoves.vue'
import GeneralInfo from './components/GeneralInfo/generalInfo.vue'
import TableBenefit from './components/TableBenefit/tableBenefit.vue'
import Anamnese from './components/Anamnese/anamnese.vue'
import Anthropometry from './components/Anthropometry/anthropometry.vue'
import Student from '@/shared/models/student'
import StudentDeficiency from '@/shared/models/studentDeficiency'
import GuidUtils from '@/shared/utils/guid'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'
import { dateHourFormattedFilter } from '@/shared/filters/ToDateFormatted'

import { mapActions, mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { globalActions as StudentActions } from '@/store/modules/student/actionTypes'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [TableContact.name]: TableContact,
    [TableHistoricSms.name]: TableHistoricSms,
    [TableRegistration.name]: TableRegistration,
    [TableAttachment.name]: TableAttachment,
    [TableOccurrence.name]: TableOccurrence,
    [TableSocialAttendance.name]: TableSocialAttendance,
    [TableMoves.name]: TableMoves,
    [GeneralInfo.name]: GeneralInfo,
    [TableBenefit.name]: TableBenefit,
    [TableMeasurement.name]: TableMeasurement,
    [Anamnese.name]: Anamnese,
    [Anthropometry.name]: Anthropometry,
    [StudentList.name]: StudentList
  },

  mixins: [Roles],

  data: () => ({
    tabIndex: 0,
    isDisabled: true,
    isNew: true,
    studentDeficiency: new StudentDeficiency(),
    student: new Student(),
    accountUpdated: [],
    showModalInfoAccountUpdated: false,
    studentInactive: {
      id: null,
      inactiveDate: null,
      reason: null,
      studentId : null
    }
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Listagem de Alunos',
            active: true
          }
        ]
      }
    },

    ...mapGetters({
      studentGetter: StudentGetters.student
    })
  },

  methods: {
    ...mapActions({
      setStudent: StudentActions.SET_STUDENT
    }),

    addStudent(newTabIndex, isDisabled) {
      this.clearForm()
      this.$validator.reset()

      this.isDisabled = isDisabled
      this.isNew = true

      this.$nextTick(() => {
        this.tabIndex = newTabIndex
      })
    },

    editStudent(newTabIndex, isDisabled, studentId) {
      this.clearForm()
      this.$validator.reset()

      this.isDisabled = isDisabled
      this.isNew = false
      this.getById(studentId)

      this.$nextTick(() => {
        this.tabIndex = newTabIndex
      })
    },

    formatDate(value) {
      if (!value || value == null) return 'Data não informada'
      const dateFormatted = dateHourFormattedFilter(value)
      return dateFormatted
    },

    getDateUpdate(date) {
      if (!date) return 'Não informado'

      const dateUpdate = dateHourFormattedFilter(date)
      return dateUpdate
    },

    getAccountNameUpdate(id) {
      if (!id) return

      if (GuidUtils.isEmpty(id)) return

      loading.push()
      StudentRepository.GetAccountUpdated(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.accountUpdated = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar conta que fez a alteração', 'ERRO')
        })
    },

    clearForm() {
      this.student = new Student()
    },

    getById(studentId) {
      loading.push()
      StudentRepository.GetById(studentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.student = res.data.data
          if (this.student.studentMeasurements.length) {
            this.student.height = this.student.studentMeasurements[0]?.height
            this.student.weight = this.student.studentMeasurements[0]?.weight
          }

          const isInactive = this.student.status != 1

          if (this.student.hasDeficiency) {
            this.getDeficiencyId()
            setTimeout(() => {
                this.$refs.GeneralInfo.checkHasDeficiency()
              }, 950)
          }

          if (isInactive) this.getReasonInactive(this.student.id)

          loading.pop()
          this.$validator.reset()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar aluno', 'ERRO')
        })
    },

    getReasonInactive(studentId) {
      loading.push()
      StudentRepository.GetReasonInactive(studentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const response = res.data.data

          if (!response) {
            this.studentInactive = {
              id: null,
              inactiveDate: null,
              reason: null,
              studentId : null
            }
          } else {
            this.studentInactive = response
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar a razão da inativação do aluno', 'ERRO')
        })
    },

    getDeficiencyId() {
      loading.push()
      StudentDeficiencyRepository.GetById(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (res.data.data.length <= 0) {
            this.$refs.GeneralInfo.disabledAllInputCid = false
            this.$refs.GeneralInfo.showButtonRemoveDeficiency = false
            loading.pop()
            return
          }

          const deficiency = res.data.data[0]

          this.student.cidCode = deficiency.cidCode
          this.student.cidCategory = deficiency.deficiencyTypeId
          this.student.deficiencyId = deficiency.id

          this.$refs.GeneralInfo.disabledAllInputCid = true
          this.$refs.GeneralInfo.showButtonRemoveDeficiency = true

          this.$refs.GeneralInfo.getCidDescription(deficiency.cidCode)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar a deficiência do aluno', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.info('É necessário preencher os campos obrigatórios antes de continuar', 'AVISO')
          return
        }

        const hasContact = this.student.contacts && this.student.contacts.length > 0

        if (!hasContact) {
          toast.info('É necessário inserir pelo menos um contato antes de continuar', 'AVISO')
          return
        }

        const hasRegistration = this.student.registrations && this.student.registrations.length > 0

        if(!hasRegistration) {
          toast.info('É necessário inserir pelo menos uma matrícula para continuar', 'AVISO')
          return
        }

        const hasDeficiency = this.student.hasDeficiency

        if (hasDeficiency) {
          const validated = this.$refs.GeneralInfo.checkValidatedInputsCid()

          if (!validated) {
            toast.info('É necessário informar qual o CID do aluno e a categoria da deficiência', 'CID - DEFICIÊCIA')
            return
          }
        }

        const isNew = this.isNew

        if (isNew) {
          this.createStudent()

          return
        }

        this.updateStudent()
      })
    },

    onSaveStudentDeficiency() {
      if (GuidUtils.isEmpty(this.student.id)) return

      this.studentDeficiency = {
        id: this.student.deficiencyId,
        studentId: this.student.id,
        deficiencyTypeId: this.student.cidCategory,
        cidCode: this.student.cidCode
      }

      loading.push()
      StudentDeficiencyRepository.Create(this.studentDeficiency)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar deficiência', 'ERRO')
        })
    },

    createStudent() {
      this.clearMeasures()

      loading.push()
      StudentRepository.Create(this.student)
        .then(res => {
          const isAlreadySaved = !IsSuccessCode(res) && res.data.code === -7

          if (isAlreadySaved) {
            toast.error('Aluno já cadastrado!', 'CRIAR ALUNO')
            loading.pop()
            return Promise.resolve()
          }

          if (!IsSuccessCode(res)) return Promise.reject()

          this.isNew = false
          this.student.id = res.data.data.id
          toast.success('Aluno criado com sucesso!', 'CRIAR ALUNO')
          window.scrollTo(0, 0)

          if (res.data.data.hasDeficiency) {
            this.onSaveStudentDeficiency()
            this.$refs.GeneralInfo.disabledAllInputCid = true
            this.$refs.GeneralInfo.showButtonRemoveDeficiency = true
          } else {
            this.$refs.GeneralInfo.disabledAllInputCid = false
            this.$refs.GeneralInfo.showButtonRemoveDeficiency = false
          }

          this.setStudent(res.data.data)
          this.$refs.Anamnese.getAnamneseByStudentId(res.data.data.id)
          this.$nextTick(() => {
            requestAnimationFrame(() => {
              this.tabIndex = 4
            })
          })

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar aluno', 'ERRO')
        })
    },

    updateStudent() {
      this.clearMeasures()

      loading.push()
      StudentRepository.Update(this.student)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getById(res.data.data.id)

          if (this.student.hasDeficiency) this.onSaveStudentDeficiency()

          toast.success('Aluno editado com sucesso!', 'EDITAR ALUNO')
          window.scrollTo(0, 0)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar aluno', 'ERRO')
        })
    },

    backTo() {
      this.$nextTick(() => {
        this.tabIndex = 0
      })
    },

    clearMeasures() {
      const hasMeasurement = this.student.height && this.student.weight

      if (hasMeasurement) {
        this.student.height = null
        this.student.weight = null
      }
    }
  }
}
