import GuidUtils from '@/shared/utils/guid'

export default class StudentAttachment {
  constructor({
    id,
    studentId,
    attachmentTypeId,
    attachmentTypeName,
    name,
    alias,
    path,
    observation,
    dateReceipt,
    dateUpdate,
    dateUpdateFormatted,
    files,
    base64,
    contentType,
    extension
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.studentId = studentId,
    this.attachmentTypeId = attachmentTypeId,
    this.attachmentTypeName = attachmentTypeName,
    this.name = name,
    this.alias = alias,
    this.path = path,
    this.observation = observation,
    this.dateReceipt = dateReceipt,
    this.dateUpdate = dateUpdate,
    this.dateUpdateFormatted = dateUpdateFormatted,
    this.files = files,
    this.base64 = base64,
    this.contentType = contentType,
    this.extension = extension
  }
}
