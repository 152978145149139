import SocialAttendanceTypeRepository from '@/shared/http/repositories/socialProject/social-attendance-type'
import SocialAttendanceRepository from '@/shared/http/repositories/socialProject/student-socialattendance'
import StudentSocialAttendance from '@/shared/models/studentSocialAttendance'
import GuidUtils from '@/shared/utils/guid'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableSocialAttendance',

  mixins: [Roles],

  data: () => ({
    socialAttendance: new StudentSocialAttendance(),
    emptyText: 'Não há atendimento social para mostrar',
    showModalDeleteSocialAttendance: false,
    showModalSocialAttendance: false,
    dateFormatted: null,
    socialAttendances: [],
    socialAttendanceTypes: [],
    fields: [
      {
        key: 'socialAttendanceDate',
        label: 'DATA DO ATENDIMENTO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'socialAttendanceTypeName',
        label: 'TIPO DO ATENDIMENTO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    clearForm() {
      this.socialAttendance = new StudentSocialAttendance()
      this.socialAttendance.studentId = this.student.id
    },

    onAddSocialAttendance() {
      this.clearForm()
      this.$validator.reset()
      this.showModalSocialAttendance = true
    },

    onEditSocialAttendance(socialAttendance) {
      this.clearForm()
      this.socialAttendance = new StudentSocialAttendance(socialAttendance)
      this.showModalSocialAttendance = true
    },

    getAllSocialAttendanceTypes() {
      loading.push()
      SocialAttendanceTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.socialAttendanceTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de atendimento social', 'ERRO')
        })
    },

    getSocialAttendanceByStudent() {
      this.clearForm()
      this.$validator.reset()
      loading.push()
      SocialAttendanceRepository.GetById(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.socialAttendances = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar atendimento social', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.socialAttendance.id)

        if(isNew){
          this.createSocialAttendance()
          return
        }

        this.updateSocialAttendance()
      })
    },

    createSocialAttendance() {
      loading.push()
      SocialAttendanceRepository.Create(this.socialAttendance)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getSocialAttendanceByStudent()
          this.showModalSocialAttendance = false
          toast.success('Atendimento social criado com sucesso!', 'CRIAR ATENDIMENTO SOCIAL')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar atendimento social', 'ERRO')
        })
    },

    updateSocialAttendance() {
      loading.push()
      SocialAttendanceRepository.Update(this.socialAttendance)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getSocialAttendanceByStudent()
          this.showModalSocialAttendance = false
          toast.success('Atendimento social editado com sucesso!', 'EDITAR ATENDIMENTO SOCIAL')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar atendimento social', 'ERRO')
        })
    },

    deleteSocialAttendance() {
      loading.push()
      SocialAttendanceRepository.Delete(this.socialAttendance.studentId, this.socialAttendance.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getSocialAttendanceByStudent()
          this.showModalDeleteSocialAttendance = false
          toast.success('Atendimento social excluído com sucesso!', 'EXCLUSÃO DE ATENDIMENTO SOCIAL')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir atendimento social', 'ERRO')
        })
    },

    onSelectSocialAttendanceType() {
      if (this.socialAttendance.socialAttendanceTypeId) {
        const socialAttendanceType = this.socialAttendanceTypes.filter(socialAttendanceType => socialAttendanceType.id === this.socialAttendance.socialAttendanceTypeId)

        if (socialAttendanceType.length > 0) {
          this.socialAttendance.socialAttendanceTypeName = socialAttendanceType[0].name
        }
      }
    },

    onDateContext(ctx) {
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.socialAttendance.socialAttendanceDate = dateFilter(event.currentTarget.value)
    },

    selectRow(id) {
      this.showModalDeleteSocialAttendance = true
      this.socialAttendance.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
