import StudentRepository from '@/shared/http/repositories/socialProject/student'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import StudentCriteria from '@/shared/models/criteria/studentcriteria'
import SendSms from '@/components/layout/SendSms/sendSms.vue'
import QRCode from '@/components/layout/QRCode/QRCode.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import statusEnum from '@/shared/enums/status'
import optionsReenrolment from '@/shared/enums/optionsReenrolment'
import Roles from '@/shared/mixins/hasRoles'
import download from 'downloadjs'
import Student from '@/shared/models/student'

import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { globalGetters as DeficiencyTypeGetters } from '@/store/modules/deficiencyType/getters'
import { globalActions as StudentActions } from '@/store/modules/student/actionTypes'
import { globalActions as DeficiencyTypeActions } from '@/store/modules/deficiencyType/actionTypes'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StudentList',

  components: {
    [Pagination.name]: Pagination,
    [SendSms.name]: SendSms,
    QRCode
  },

  mixins: [Roles],

  data: () => ({
    criteria: new StudentCriteria(),
    optionsStatus: Object.values(statusEnum),
    optionsReenrolment: Object.values(optionsReenrolment),
    emptyText: 'Não há registros para mostrar',
    showModalStudent: false,
    showModalMatriculateButton: false,
    showHeader: false,
    selected: {},
    toQrCode: {},
    showMoreAction: false,
    chooseMethodSendProof: false,
    chooseMethodSms: false,
    chooseMethodQrCode: false,
    choosedProof: null,
    proofEmails: [],
    proofEmail: null,
    itemToSendSMS: [],
    reasonInactive: null,
    reasonSelect: null,
    status: false,
    isStudentSms: true,
    modelReasonInactiveStudent: null,
    optionsInactive: [
      {id: 'Desligado por Faltas', name: 'Desligado por Faltas'},
      {id: 'Desistiu da Vaga', name: 'Desistiu da Vaga'},
      {id: 'Mudou de Cidade', name: 'Mudou de Cidade'},
    ],
    fields: [
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'profilePhoto',
        label: 'FOTO',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'ALUNO',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'documentRG',
        label: 'RG',
        class: 'middle-header text-center'
      },
      {
        key: 'documentCPF',
        label: 'CPF',
        class: 'middle-header text-center'
      },
      {
        key: 'statusText',
        label: 'SITUAÇÃO',
        class: 'middle-header text-center'
      },
      {
        key: 'yearReenrollment',
        label: 'ANO DE REMATRÍCULA',
        class: 'middle-header text-center'
      },
      {
        key: 'enrollment',
        label: 'MATRÍCULA',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    specialCharacters: [
      {str: '@RESPNAME@'},
      {str: '@STUDENTCODE@'},
      {str: '@STUDENTBIRTH@'},
      {str: '@STUDENTNAME@'},
      {str: '@STUDENTCPF@'},
      {str: '@STUDENTRG@'},
      {str: '@STUDENTSTATUS@'},
      {str: '@STUDENTREENROLMENT@'},
      {str: '@DATENOW@'},
      {str: '@DATETOMORROW@'},
      {str: '@DATEYESTERDAY@'}
    ],
    pagination: {
      count: 0,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    ...mapGetters({
      students: StudentGetters.students,
      student: StudentGetters.student,
      deficiencyTypes: DeficiencyTypeGetters.deficiencyTypes,
      paginationGetter: StudentGetters.pagination
    })
  },

  created() {
    this.fetchDeficiencyTypes()
  },

  methods: {
    ...mapActions({
      fetchStudents: StudentActions.FETCH_STUDENTS,
      setStudents: StudentActions.SET_STUDENTS,
      setPagination: StudentActions.SET_PAGINATION,
      setStudent: StudentActions.SET_STUDENT,
      fetchDeficiencyTypes: DeficiencyTypeActions.FETCH_DEFICIENCY_TYPES,
      setStudentCounter: StudentActions.SET_STUDENT_COUNTER
    }),

    async getAll() {
      await this.fetchStudents({ criteria: this.criteria, pagination: this.pagination })

      this.pagination = {
        count: this.paginationGetter.count,
        limit: this.paginationGetter.limit,
        index: this.paginationGetter.index
      }
    },

    addStudent() {
      this.setStudent(new Student())
      this.$emit('addStudent', 1, false)
    },

    editStudent(student) {
      this.setStudent(new Student())
      this.setStudent(student)
      this.$emit('editStudent', 1, false, student.id)
    },

    resetForm() { this.criteria = new StudentCriteria() },

    onShowMoreAction(item) {
      this.showMoreAction = true
      this.selected = item
    },

    onCloseMoreAction() {
      this.showMoreAction = false
      this.selected = {}
      this.proofEmail = null
    },

    onProofEnrollment() {
      this.showMoreAction = false
      if (this.selected.status != 1) {
        toast.info('Este aluno não pode gerar comprovante', 'ALUNO NÃO MATRICULADO')
        return
      }
      this.chooseMethodSendProof = true
      if (this.selected.email == null) {
        this.selected.email = []
      }
      this.proofEmails = this.selected.email
    },

    onSmsService() {
      let toArray = [this.selected].map(x => ({
        studentId: x.id,
        name: x.name,
        studentCode: x.code,
        studentBirth: x.birthDate,
        contacts: x.contacts,
        studentCPF: x.documentCPF,
        studentRG: x.documentRG,
        studentStatus: x.statusText,
        studentReenrollment: x.yearReenrollment
      }))

      this.itemToSendSMS = toArray
      this.showMoreAction = false
      this.chooseMethodSms = true
    },

    onQrCode(obj) {
      this.showMoreAction = false
      if (this.selected.status != 1) {
        toast.info('Este aluno não pode gerar QRCODE', 'ALUNO NÃO MATRICULADO')
        return
      }

      this.toQrCode = obj
      this.showMoreAction = false
      this.chooseMethodQrCode = true
    },

    onCloseChooseMethodProof() {
      this.chooseMethodSendProof = false
      this.choosedProof = null
      this.proofEmails = []
      this.proofEmail = null
      this.selected = {}
    },

    validateEmail(email) {
      let regex = /\S+@\S+\.\S+/
      let result = regex.test(email)

      if (!result) return false

      return true
    },

    addInputProofEmail(email) {
      if (!email) return
      this.proofEmail = email
    },

    sendEmailEnrollment(email) {
      this.selected.email = email
      loading.push()
      StudentRepository.ProofEnrollmentSendEmail(this.selected, this.selected.id)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

          toast.success(`E-mail enviado para: ${email}`, 'ENVIO DE E-MAIL')
          this.chooseMethodSendProof = false
          this.selected.email = null
          this.proofEmail = null

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao enviar e-mail', 'ERRO')
        })
    },

    proofEnrollment() {
      loading.push()
      StudentRepository.ProofEnrollmentGeneratePDF(this.selected.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Comprovante de Matrícula Gerado com Sucesso!', 'DECLARAÇÃO')

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao gerar comprovante de matrícula', 'ERRO')
        })
    },

    onDelete() {
      loading.push()

      if (this.reasonSelect == null && this.reasonInactive == null) {
        toast.info('Preencha alguma razão para inativar este aluno(a)', 'INATIVAR ALUNO')
        loading.pop()
        return
      }

      StudentRepository.Delete(this.id)
        .then(async res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          await this.onReasonStudentInactive(this.id)
          this.closeModalStudent()
          this.getAll()

          toast.success('Aluno excluído com sucesso!', 'EXCLUSÃO DE ALUNO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir aluno', 'ERRO')
        })
    },

    onReasonStudentInactive(studentId) {
      if(this.status == 'showTextArea') this.reasonSelect = null
      if(this.status == 'hideTextArea') this.reasonInactive = null

      this.modelReasonInactiveStudent = {
        studentId: studentId,
        reason: this.reasonInactive || this.reasonSelect,
        InactiveDate: null
      }

      loading.push()
      StudentRepository.ReasonInactiveStudent(this.modelReasonInactiveStudent)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao inserir razão pra inativação', 'ERRO')
        })
    },

    closeModalStudent() {
      this.showModalStudent = false
      this.status = false
      this.reasonSelect = null
      this.reasonInactive = null
    },

    selectRow(item) {
      const isInactiveStudent = item.status != 1
      if (isInactiveStudent) {
        toast.info('Este cadastro já esta inativo ou cancelado', 'DELETAR ALUNO')
        return
      }
      this.showModalStudent = true
      this.id = item.id
    },

    showButtonUpdateReenrollment(currentYearReenrollment) {
      let dt = new Date();
      let day = dt.getDate();
      let month = dt.getMonth();
      let year = dt.getFullYear();

      const isJanuaryRole = month === 0 && day >= 6 // Abrindo a rematricula
      const isFebruaryRole = month === 1
      const isMarchRole = month === 2 && day <= 20
      const isCurrentYearReenrollment = currentYearReenrollment === year
      // Apagar
      const untilOctober = month <= 9

      if (isCurrentYearReenrollment) {
        return false
      }

      if (isJanuaryRole){
        this.showHeader = true
        return true
      }

      if (isFebruaryRole) {
        this.showHeader = true
        return true
      }

      if (isMarchRole) {
        this.showHeader = true
        return true
      }

      // Apagar
      if (untilOctober) {
        this.showHeader = true
        return true
      }

      this.showHeader = false
      return false
    },

    showModalMatriculateMethod(item) {
      this.showModalMatriculateButton = true
      this.selected = item
    },

    showButtonMatriculate(currentYearReenrollment) {
      let dt = new Date()
      let day = dt.getDate();
      let month = dt.getMonth();
      let year = dt.getFullYear();

      const isCurrentYearReenrollment = currentYearReenrollment === year
      const isJanuaryRole = month === 0 && day >= 6
      const isFebruaryRole = month === 1
      const isMarchRole = month === 2 && day <= 20

      // Apagar
      const finishMonth = month <= 9

      if (isCurrentYearReenrollment && (isJanuaryRole || isFebruaryRole || isMarchRole || finishMonth)) {
        return true
      }
      return false
    },

    updateYearReenrollment() {
      if (!this.selected){
        this.showModalMatriculateButton = false
        return
      }
      loading.push()
      StudentRepository.UpdateReenrollment(this.selected.id)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

        this.showModalMatriculateButton = false
        const studentIndex = this.students.findIndex(student => student.id === this.selected.id)
        this.students[studentIndex].yearReenrollment =  new Date().getFullYear()
        toast.success('Aluno rematriculado com sucesso!', 'REMATRÍCULA')
        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao rematricular aluno', 'ERRO')
      })
    },

    onFaceSheet(item) {
      const studentId = item.id

      if (!studentId) return

      loading.push()
      StudentRepository.FaceSheet(studentId)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

        toast.success('PDF Gerado com sucesso!', 'FOLHA DE ROSTO')

        const file = res.data.data
        const base64 = `data:${file.contentType};base64,${file.base64}`
        download(base64, file.alias, file.contentType)

        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.warning('Verifique o cadastro do aluno', 'FOLHA DE ROSTO')
      })
    },

    onCloseModalSendSMS() {
      this.chooseMethodSms = false
    },
    onCloseModalQrCode() {
      this.chooseMethodQrCode = false
    }
  }
}
