import RegistrationRepository from '@/shared/http/repositories/socialProject/student-registration'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableMoves',

  data: () => ({
    inactiveRegistrations: [],
    emptyText: 'Não há movimentos para mostrar',
    fields: [
      {
        key: 'dateStart',
        label: 'DATA DA MATRÍCULA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'dateEnd',
        label: 'DATA DA SAÍDA',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL',
        class: 'middle-header text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'categoryName',
        label: 'CATEGORIA',
        class: 'middle-header text-center'
      },
      {
        key: 'className',
        label: 'TURMA',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    getAllInactivesByStudent() {
      loading.push()
      RegistrationRepository.GetInactive(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.inactiveRegistrations = res.data.data.filter(move => move.status === 0)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar as matrículas inativas', 'ERRO')
        })
    }
  }
}
