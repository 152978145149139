import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'anthropometry'

const GetAll = pagination => SocialProjectManager.get(resourceName, pagination)
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetByStudentId = (studentId, pagination) => SocialProjectManager.get(`${resourceName}/student/${studentId}`, { params: pagination })

const Create = data => SocialProjectManager.post(`${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const AnthropometryRepository = {
  GetAll,
  GetById,
  GetByStudentId,
  Create,
  Update,
  Delete
}

export default AnthropometryRepository
