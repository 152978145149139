import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'benefit'
const resourceStudent = 'student'

const GetById = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)

const Create = data => SocialProjectManager.post(`${resourceStudent}/${data.studentId}/${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceStudent}/${data.studentId}/${resourceName}/${data.id}`, data)

const Delete = (studentId, id) => SocialProjectManager.delete(`${resourceStudent}/${studentId}/${resourceName}/${id}`)

const BenefitRepository = {
  GetById,
  Create,
  Update,
  Delete
}

export default BenefitRepository