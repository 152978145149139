import AttachmentRepository from '@/shared/http/repositories/socialProject/student-attachment'
import AttachmentTypeRepository from '@/shared/http/repositories/socialProject/attachment-type'
import StudentAttachment from '@/shared/models/studentAttachment'
import GuidUtils from '@/shared/utils/guid'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { JWTService } from '@/shared/services/jwt'
import download from 'downloadjs'
import Pagination from '@/components/layout/Pagination/pagination.vue'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableAttachment',

  mixins: [Roles],

  components: {
    vueDropzone: vue2Dropzone,
    [Pagination.name]: Pagination
  },

  data: () => ({
    emptyText: 'Não há anexos para mostrar',
    showModalDeleteAttachment: false,
    showModalAttachment: false,
    showModalDeleteFile:  false,
    attachment: new StudentAttachment(),
    attachmentTypes: [],
    attachments: [],
    attachmentId: null,
    fileId: null,
    openDrawer: false,
    attachmentFiles: [],
    attach: [],
    dateAttachFormatted: null,
    disableSaveBtn: false,
    disableDownloadBtn: false,
    fields: [
      {
        key: 'dateReceipt',
        label: 'DATA DO ANEXO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'attachmentTypeName',
        label: 'TIPO DO ANEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    attachFields: [
      {
        key: 'alias',
        label: 'NOME',
        class: 'left-header-border text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    dropzoneOptions: {
      url: () => '',
      method: 'POST',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      addRemoveLinks: true,
      maxFilesize: 100,
      uploadMultiple: true,
      parallelUploads: 10,
      headers: { "Authorization": `Bearer ${JWTService.GetToken()}` },
      dictDefaultMessage: '<i style="font-size: 50px;" class="fa fa-cloud-upload"></i><p>Clique ou arraste os arquivos que deseja anexar.</p',
      dictRemoveFile: 'Remover',
      dictCancelUpload: 'Cancelar upload'
    },
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    clearForm() {
      this.attachment = new StudentAttachment(),
      this.attachment.studentId = this.student.id
    },

    onAddAttachment() {
      this.clearForm()
      this.$validator.reset()
      this.showModalAttachment = true
    },

    onEditAttachment(attachment) {
      this.clearForm()
      this.attachment = new StudentAttachment(attachment),
      this.showModalAttachment = true
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.attachment.id)
        this.attachmentId = null
        this.disableSaveBtn = true

        if(isNew){
          this.createAttachment()
          return
        }

        this.updateAttachment()
      })
    },

    async createAttachment() {
      loading.push()
      await AttachmentRepository.Create(this.attachment)
        .then(async res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          const msg = 'Anexo criado com sucesso'
          const msgType = 'CRIAR ANEXO'
          this.attachmentId = res.data.data.id
          await this.uploadAttachmentFile(this.attachmentId, msg, msgType)

          await this.getAttachmentByStudent()

          this.showModalAttachment = false
          this.disableSaveBtn = false
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar anexo', 'ERRO')
        })
    },

    async uploadAttachmentFile(attachmentId, msg, msgType) {
      loading.push()
      await AttachmentRepository.UploadFile(attachmentId, this.$refs.myVueDropzone.dropzone.files)
        .then(res => {
          if(res.status !== 200) return Promise.reject()

          loading.pop()
          toast.success(msg, msgType)
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar arquivo', 'ERRO')
        })
    },

    async updateAttachment() {
      loading.push()
      await AttachmentRepository.Update(this.attachment)
        .then(async res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const msg = 'Anexo editado com sucesso'
          const msgType = 'EDITAR ANEXO'
          this.attachmentId = this.attachment.id
          await this.uploadAttachmentFile(this.attachmentId, msg, msgType)

          await this.getAttachmentByStudent()

          this.showModalAttachment = false
          this.disableSaveBtn = false
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar anexo', 'ERRO')
        })
    },

    deleteAttachment() {
      loading.push()
      AttachmentRepository.Delete(this.attachment.studentId, this.attachment.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAttachmentByStudent()
          this.showModalDeleteAttachment = false
          toast.success('Anexo excluído com sucesso!', 'EXCLUSÃO DE ANEXO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir anexo', 'ERRO')
        })
    },

    deleteAttachmentFile() {
      loading.push()
      AttachmentRepository.DeleteFile(this.fileId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const index = this.attachmentFiles.findIndex(file => file.id === this.fileId)
          if (~index) {
            this.attachmentFiles.splice(index, 1)
          }

          this.showModalDeleteFile = false
          this.getAttachmentByStudent()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir arquivo em anexo', 'ERRO')
        })
    },

    getAllAttachmentTypes() {
      loading.push()
      AttachmentTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.attachmentTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de anexo', 'ERRO')
        })
    },

    async getAttachmentByStudent() {
      this.clearForm()
      this.$validator.reset()
      loading.push()
      await AttachmentRepository.GetById(this.student.id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.attachments = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar anexos', 'ERRO')
        })
    },

    async getAttachmentFiles(attachmentId) {
      loading.push()
      await AttachmentRepository.GetFilesByAttachmentId(attachmentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.attachmentFiles = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar arquivos do anexo.', 'ERRO')
        })
    },

    selectRow(id) {
      this.showModalDeleteAttachment = true
      this.attachment.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    downloadAllByAttachmentId(attachmentId) {
      this.disableDownloadBtn = true
      loading.push()
      AttachmentRepository.DownloadAttachment(attachmentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          loading.pop()

          setTimeout(() => {
            this.disableDownloadBtn = false
          }, 1000)
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.disableDownloadBtn = false
          toast.error('Erro ao realizar download do arquivo.', 'ERRO')
        })
    },

    downloadByAttachmentFileId(attachmentFileId) {
      this.disableDownloadBtn = true
      loading.push()
      AttachmentRepository.DownloadAttachmentFile(attachmentFileId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          loading.pop()

          setTimeout(() => {
            this.disableDownloadBtn = false
          }, 1000)
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.disableDownloadBtn = false
          toast.error('Erro ao realizar download do arquivo.', 'ERRO')
        })
    },

    showDrawer(attach) {
      this.attach = attach
      this.attachmentFiles = []
      this.getAttachmentFiles(attach.id)
      this.dateAttachFormatted = dateFormattedFilter(this.attach.dateReceipt)
      this.openDrawer = true
    },

    onCloseDrawer() {
      this.openDrawer = false
      this.attach = []
    },

    selectFileRow(id) {
      this.showModalDeleteFile = true
      this.fileId = id
    }
  }
}
