import Anthropometry from '@/shared/models/anthropometry'
import AnthropometryRepository from '@/shared/http/repositories/socialProject/anthropometry'
import GuidUtils from '@/shared/utils/guid'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { noValue } from '@/shared/filters/NoValue'
import { replacePointToComma } from '@/shared/filters/ReplacePointToComma'
import DefaultMixin from '@/shared/mixins/defaultMixin'

import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'Anthropometry',

  mixins: [DefaultMixin],

  data: () => ({
    emptyText: 'Não há antropometrias para serem mostradas.',
    anthropometries: [],
    anthropometry: new Anthropometry(),
    showModalAnthropometry: false,
    showModalDelete: false,
    isFemale: null,
    fields: [
      {
        key: 'date',
        label: 'DATA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'weight',
        label: 'PESO (Kg)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'height',
        label: 'ALTURA (m)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'abdominalCirc',
        label: 'CA (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'armCirc',
        label: 'CB (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'thighCirc',
        label: 'C COXA (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'calfCirc',
        label: 'C PANT (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'chestCirc',
        label: 'C PEITO (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'hip',
        label: 'QUADRIL (cm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'triceps',
        label: 'TRICEPS (mm)',
        class: 'middle-header text-center',
        formatter: noValue

      },
      {
        key: 'chest',
        label: 'PEITO (mm)',
        class: 'middle-header text-center',
        formatter: noValue
      },
      {
        key: 'abdominal',
        label: 'ABD (mm)',
        class: 'middle-header text-center',
        formatter: noValue
      },
      {
        key: 'supraIliac',
        label: 'SI (mm)',
        class: 'middle-header text-center',
        formatter: noValue
      },
      {
        key: 'thighCrease',
        label: 'P COXA (mm)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'bodyFat',
        label: 'PCG%',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    clearForm() {
      this.anthropometry = new Anthropometry()
      this.anthropometry.studentId = this.student.id
    },

    onAdd() {
      this.clearForm()
      this.$validator.reset()
      this.showModalAnthropometry = true
    },

    getAllByStudent() {
      loading.push()
      AnthropometryRepository.GetByStudentId(this.student.id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.anthropometries = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar antropometrias.', 'ERRO')
        })
    },

    getGender(gender) {
      this.gender = gender
      this.gender !== 1 ? this.isFemale = false : this.isFemale = true
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher todos os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.anthropometry.id)

        this.anthropometry.weight = this.replaceCommaWithPoint(this.anthropometry.weight)
        this.anthropometry.height = this.replaceCommaWithPoint(this.anthropometry.height)
        this.anthropometry.abdominalCirc = this.replaceCommaWithPoint(this.anthropometry.abdominalCirc)
        this.anthropometry.armCirc = this.replaceCommaWithPoint(this.anthropometry.armCirc)
        this.anthropometry.thighCirc = this.replaceCommaWithPoint(this.anthropometry.thighCirc)
        this.anthropometry.calfCirc = this.replaceCommaWithPoint(this.anthropometry.calfCirc)
        this.anthropometry.chestCirc = this.replaceCommaWithPoint(this.anthropometry.chestCirc)
        this.anthropometry.hip = this.replaceCommaWithPoint(this.anthropometry.hip)
        this.anthropometry.triceps = this.replaceCommaWithPoint(this.anthropometry.triceps)
        this.anthropometry.chest = this.replaceCommaWithPoint(this.anthropometry.chest)
        this.anthropometry.abdominal = this.replaceCommaWithPoint(this.anthropometry.abdominal)
        this.anthropometry.supraIliac = this.replaceCommaWithPoint(this.anthropometry.supraIliac)
        this.anthropometry.thighCrease = this.replaceCommaWithPoint(this.anthropometry.thighCrease)
        this.anthropometry.bodyFat = this.replaceCommaWithPoint(this.anthropometry.bodyFat)

        if (isNew) {
          this.onCreate()
          return
        }

        this.onUpdate()
      })
    },

    onCreate() {
      loading.push()
      AnthropometryRepository.Create(this.anthropometry)
        .then(res => {
          if (!IsSuccessCode(res)) Promise.reject()

          this.showModalAnthropometry = false
          this.getAllByStudent()
          toast.success('Antropometria salva com sucesso!', 'SALVAR ANTROPOMETRIA')

          loading.pop()
          Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar antropometria.', 'ERRO')
        })
    },

    onUpdate() {
      loading.push()
      AnthropometryRepository.Update(this.anthropometry)
        .then(res => {
          if (!IsSuccessCode(res)) Promise.reject()

          this.showModalAnthropometry = false
          this.getAllByStudent()
          toast.success('Antropometria editada com sucesso!', 'EDITAR ANTROPOMETRIA')

          loading.pop()
          Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar antropometria.', 'ERRO')
        })
    },

    onEdit(anthropometry) {
      this.clearForm()
      this.anthropometry = new Anthropometry(anthropometry)
      this.showModalAnthropometry = true
    },

    selectRow(id) {
      this.showModalDelete = true
      this.anthropometry.id = id
    },

    onDelete() {
      loading.push()
      AnthropometryRepository.Delete(this.anthropometry.id)
        .then(res => {
          if (!IsSuccessCode(res)) Promise.reject()

          this.showModalDelete = false
          this.getAllByStudent()
          toast.success('Antropometria excluída com sucesso!', 'EXCLUSÃO DE ANTROPOMETRIAS')

          loading.pop()
          Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir antropometria.', 'ERRO')
        })
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
