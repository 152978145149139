import GuidUtils from '@/shared/utils/guid'

export default class StudentOccurrence {
  constructor({
    id,
    studentId,
    occurrenceTypeId,
    occurrenceTypeName,
    occurrenceDate,
    description
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.studentId = studentId,
    this.occurrenceTypeId = occurrenceTypeId,
    this.occurrenceTypeName = occurrenceTypeName,
    this.occurrenceDate = occurrenceDate,
    this.description = description
  }
}
