import OccurrenceTypeRepository from '@/shared/http/repositories/socialProject/occurrence-type'
import OccurrenceRepository from '@/shared/http/repositories/socialProject/student-occurrence'
import StudentOccurrence from '@/shared/models/studentOccurrence'
import GuidUtils from '@/shared/utils/guid'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableOccurrence',

  mixins: [Roles],

  data: () => ({
    emptyText: 'Não há ocorrências para mostrar',
    occurrence: new StudentOccurrence(),
    dateFormatted: null,
    showModalDeleteOccurrence: false,
    showModalOccurrence: false,
    occurrences: [],
    occurrenceTypes: [],
    fields: [
      {
        key: 'occurrenceDate',
        label: 'DATA DA OCORRÊNCIA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'occurrenceTypeName',
        label: 'TIPO DA OCORRÊNCIA',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    clearForm() {
      this.occurrence = new StudentOccurrence()
      this.occurrence.studentId = this.student.id
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.occurrence.id)

        if(isNew){
          this.createOccurrence()
          return
        }

        this.updateOccurrence()
      })
    },

    getAllOccurrenceType() {
      loading.push()
      OccurrenceTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.occurrenceTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de ocorrência', 'ERRO')
        })
    },

    getOccurrenceByStudent() {
      this.clearForm()
      this.$validator.reset()
      loading.push()
      OccurrenceRepository.GetById(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.occurrences = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de ocorrência', 'ERRO')
        })
    },

    createOccurrence() {
      loading.push()
      OccurrenceRepository.Create(this.occurrence)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getOccurrenceByStudent()
          this.showModalOccurrence = false
          toast.success('Ocorrência criada com sucesso!', 'CRIAR OCORRÊNCIA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar ocorrência', 'ERRO')
        })
    },

    onAddOccurrence() {
      this.clearForm()
      this.$validator.reset()
      this.showModalOccurrence = true
    },

    onEditOccurrence(occurrence) {
      this.clearForm()
      this.occurrence = new StudentOccurrence(occurrence)
      this.showModalOccurrence = true
    },

    updateOccurrence() {
      loading.push()
      OccurrenceRepository.Update(this.occurrence)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getOccurrenceByStudent()
          this.showModalOccurrence = false
          toast.success('Ocorrência editada com sucesso!', 'EDITAR OCORRÊNCIA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar ocorrência', 'ERRO')
        })
    },

    deleteOccurrence() {
      loading.push()
      OccurrenceRepository.Delete(this.occurrence.studentId, this.occurrence.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getOccurrenceByStudent()
          this.showModalDeleteOccurrence = false
          toast.success('Ocorrência excluída com sucesso!', 'EXCLUSÃO DE OCORRÊNCIA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir ocorrência', 'ERRO')
        })
    },

    onDateContext(ctx) {
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.occurrence.occurrenceDate = dateFilter(event.currentTarget.value)
    },

    onSelectOccurrenceType() {
      if (this.occurrence.occurrenceTypeId) {
        const occurrenceType = this.occurrenceTypes.filter(occurrenceType => occurrenceType.id === this.occurrence.occurrenceTypeId)

        if (occurrenceType.length > 0) {
          this.occurrence.occurrenceTypeName = occurrenceType[0].name
        }
      }
    },

    selectRow(id) {
      this.showModalDeleteOccurrence = true
      this.occurrence.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
