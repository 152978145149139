import Vue from 'vue'
import numeral from 'numeral'
import 'numeral/locales/pt-br'

// eslint-disable-next-line
export const moneyFormattedFilter = (value) => {
  if (!value) return '0'

  numeral.locale('pt-br')

  const d = numeral(value)
  return d.format('0,0.00')
}

Vue.filter('ToMoneyFormatted', moneyFormattedFilter)