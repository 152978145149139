import GuidUtils from '@/shared/utils/guid'

export default class StudentSocialAttendance {
  constructor({
    id,
    studentId,
    socialAttendanceTypeId,
    socialAttendanceTypeName,
    socialAttendanceDate,
    description
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.studentId = studentId,
    this.socialAttendanceTypeId = socialAttendanceTypeId,
    this.socialAttendanceTypeName = socialAttendanceTypeName,
    this.socialAttendanceDate = socialAttendanceDate,
    this.description = description
  }
}
