import MeasurementRepository from '@/shared/http/repositories/socialProject/student-measurement'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { replacePointToComma } from '@/shared/filters/ReplacePointToComma'
import MeasurementModel from '@/shared/models/measurementModel'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import GuidUtils from '@/shared/utils/guid'
import Roles from '@/shared/mixins/hasRoles'

import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'TableMeasurement',

  mixins: [Roles],

  data: () => ({
    measurement: new MeasurementModel(),
    emptyText: 'Não há medições para mostrar',
    showModalMeasurement: false,
    showModalDeleteMeasurement: false,
    height: null,
    weight: null,
    measurements: [],
    fields: [
      {
        key: 'measurementDate',
        label: 'DATA DA MEDIÇÃO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'height',
        label: 'ALTURA (m)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'weight',
        label: 'PESO (Kg)',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    getAllMeasurement() {
      loading.push()

      if (this.student.id) {
        MeasurementRepository.GetAllMeasurementById(this.student.id)
          .then(res => {
            if (!IsSuccessCode(res)) return Promise.reject()

            this.measurements = res.data.data
            loading.pop()
            return Promise.resolve()
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao carregar medições', 'ERRO')
          })
      }
      loading.pop()
    },

    onDelete() {
      loading.push()
      MeasurementRepository.Delete(this.student.id, this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAllMeasurement()
          this.showModalDeleteMeasurement = false
          toast.success('Medição excluída com sucesso!', 'EXCLUSÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir medição', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        this.measurement.height = this.measurement.height.replace(",",".")
        this.measurement.weight = this.measurement.weight.replace(",",".")

        const isNew = GuidUtils.isEmpty(this.measurement.id)

        if(isNew){
          this.createStudentMeasurement()
          return
        }

        this.updateStudentMeasurement()
      })
    },

    createStudentMeasurement() {
      loading.push()

      MeasurementRepository.Create(this.student.id, this.measurement)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAllMeasurement()
          this.showModalMeasurement = false
          toast.success('Medida criada com sucesso!', 'CRIAÇÃO DE MEDIÇÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar medida', 'ERRO')
        })
    },

    updateStudentMeasurement(){
      loading.push()
      MeasurementRepository.Update(this.student.id, this.measurement.id, this.measurement)
        .then(result => {
          if(!IsSuccessCode(result)) return Promise.reject()

          this.getAllMeasurement()
          this.showModalMeasurement = false
          toast.success('Registro atualizado com sucesso', 'SUCESSO')
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível atualizar o registro.', 'ERRO')
          loading.pop()
        })
    },

    onEditMeasurement(studentMeasurement) {
      this.onClear()
      this.measurement = new MeasurementModel(studentMeasurement)
      this.showModalMeasurement = true
    },

    newMeasurement() {
      this.onClear()
      this.showModalMeasurement = true
    },

    selectRow(id) {
      this.showModalDeleteMeasurement = true
      this.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    onClear () {
      this.measurement = new MeasurementModel()
    }
  }
}
