import StudentRepository from '@/shared/http/repositories/socialProject/student'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'

import { mapGetters } from 'vuex'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'

export default {
  name: 'TableHistoricSms',

  data: () => ({
    messagesSms: [],
    emptyText: 'Não há registro de SMS',
    fields: [
      {
        key: 'historicDate',
        label: 'DATA DO ENVIO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'phone',
        label: 'TELEFONE',
        class: 'middle-header text-center',
      },
      {
        key: 'messageOwner',
        label: 'AUTOR',
        class: 'middle-header text-center'
      },
      {
        key: 'message',
        label: 'MENSAGEM',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    getHistoricByStudent() {
      loading.push()
      StudentRepository.GetHistoricSms(this.student.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.messagesSms = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar histórico de sms', 'ERRO')
        })
    },

    toast(data, info) {
      this.$bvToast.toast(`${data.message}`, {
        title: `${data.messageOwner}`,
        toaster: 'b-toaster-top-full',
        solid: true,
        variant: info
      })
    }
  }
}
