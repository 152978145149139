import GuidUtils from '@/shared/utils/guid'

export default class StudentBenefit {
  constructor({
  id,
  studentId,
  benefitTypeId,
  benefitTypeName,
  value,
  observations
  } = {}) {
  this.id = id || GuidUtils.emptyGuid,
  this.studentId = studentId,
  this.benefitTypeId = benefitTypeId,
  this.benefitTypeName = benefitTypeName,
  this.value = value,
  this.observations = observations
  }
}
