export default class StudentCriteria {
  constructor({
    code,
    name,
    status,
    typeStudentReenrolment,
    deficiencyTypeId,
  } = {}) {
    this.code = code
    this.name = name
    this.status = status
    this.typeStudentReenrolment = typeStudentReenrolment
    this.deficiencyTypeId = deficiencyTypeId
  }
}