import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'attachment'
const resourceStudent = 'student'
const resourceFile = 'attachmentfile'
const attachmentUpload = 'upload'
const attachmentDownload = 'download'

const GetById = (studentId, pagination) => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`,
  { params: pagination }
)
const GetAll = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)
const GetFilesByAttachmentId = attachmentId => SocialProjectManager.get(`${resourceStudent}/${attachmentId}/${resourceFile}`)

const Create = data => SocialProjectManager.post(`${resourceStudent}/${data.studentId}/${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceStudent}/${data.studentId}/${resourceName}/${data.id}`, data)

const Delete = (studentId, id) => SocialProjectManager.delete(`${resourceStudent}/${studentId}/${resourceName}/${id}`)
const DeleteFile = id => SocialProjectManager.delete(`${resourceStudent}/${resourceFile}/${id}`)

const UploadFile = (attachmentId, files) => SocialProjectManager.post(`${resourceStudent}/${attachmentId}/${resourceFile}/${attachmentUpload}`, files, {
  transformRequest(data) {
    const form = new FormData();
    (data || []).forEach((f, i) => form.append(`file${i}`, f, f.name))
    return form
  }
})

const DownloadAttachment = attachmentId => SocialProjectManager.get(`${resourceStudent}/${attachmentId}/${resourceName}/${attachmentDownload}`)
const DownloadAttachmentFile = attachmentFileID => SocialProjectManager.get(`${resourceStudent}/${attachmentFileID}/${resourceFile}/${attachmentDownload}`)

const AttachmentRepository = {
  GetById,
  GetAll,
  GetFilesByAttachmentId,
  Create,
  Update,
  Delete,
  DeleteFile,
  UploadFile,
  DownloadAttachment,
  DownloadAttachmentFile
}

export default AttachmentRepository
